// src/v1/Landing.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
import WaitlistSignupForm from './WaitlistSignupForm';
import './css/Landing.css';

function Landing() {
    const [showForm, setShowForm] = useState(false);
    const [isSignedUp, setIsSignedUp] = useState(false); // Track signup status

    const handleSignupSuccess = () => {
        setIsSignedUp(true);
        setShowForm(false); // Optionally hide form on success
    }

    return (
        <div className="page-wrapper">
            <div className="top-container">
                <div className="logo-container">
                    <Link to="/" className="logo-link-bg">
                        <span className="logo-bg">🚀 Altversity </span>
                    </Link>
                    <Link to="/" className="logo-link-bg">
                        <button className="btn btn-light btn-beta">BETA</button>
                    </Link>
                </div>
                <div className="header-container">
                    <div className="header-text">
                        <h1 className="page-header">The All-in-one place for learning</h1>
                        <div className="typed-text">
                            <Typewriter
                                options={{
                                    strings: ['Programming', 'Artificial Intelligence', 'Blockchain', 'Cryptocurrency', 'Cybersecurity', 'Data Science', 'Computer Science', 'Quantum Computing',],
                                    autoStart: true,
                                    loop: true,
                                }}
                            />
                        </div>
                    </div>
                    <div className="header-tagline">
                        <span className="tagline-text">Content discovery. Personal AI learning assistant. Learning management.</span>
                    </div>
                </div>
                <div className="signup-container">
                    {!isSignedUp && !showForm && (
                        // <button className="btn btn-light btn-join" onClick={() => setShowForm(!showForm)}>
                        //     {showForm ? "Cancel" : "Join the waitlist!"}
                        // </button>
                        <button className="btn btn-light btn-join" onClick={() => setShowForm(!showForm)}>
                            Join the waitlist!
                        </button>
                    )}
                    {isSignedUp ? (
                        <div className="success-container">
                            <p>Thank you for signing up! 😘 We will be in touch soon! 💌 </p>
                            <button className="btn btn-light btn-join">Waitlist Joined!</button>
                        </div>
                    ) : (
                        <div className="form-container">
                            {showForm && <WaitlistSignupForm onClose={() => setShowForm(false)} onSuccess={handleSignupSuccess} />}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

};

export default Landing; 