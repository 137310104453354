// src/v1/WaitlistSignupForm.js

import React, { useState } from 'react';
import './css/Form.css';

const WaitlistSignupForm = ({ onClose, onSuccess }) => {
    // Setting API base URL
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

    const [userData, setUserData] = useState({
        name: '',
        email: '',
        timestamp: new Date().toISOString()
    });

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/api/waitlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData)
            });
            if (response.ok) {
                alert('Yay! Signed up successfully! 😍');
                onSuccess();
            } else {
                alert('Failed to sign up. 😢');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error signing up. 😐');
        }
    };

    return (
        <form className="waitlist-form" id="waitlist-form" onSubmit={handleSubmit} >
            <label htmlFor="name" className="form-label">Name:
                <input type="text" className="form-control form-input" id="name" name="name" value={userData.name} onChange={handleChange} required autoComplete="name" />
            </label>
            <label htmlFor="email" className="form-label">Email:
                <input type="email" className="form-control form-input" id="email" name="email" value={userData.email} onChange={handleChange} required autoComplete="email" />
            </label>
            <div className="btn-group">
                <button type="submit" className="btn btn-light btn-signup">Sign Up</button>
                <button type="button" className="btn btn-light btn-cancel" onClick={onClose}>Cancel</button>
            </div>
        </form>
    );
};

export default WaitlistSignupForm; 