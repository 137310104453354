// src/v1/Footer.js

import React from "react";
import './css/App.css';

function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer>
            <p>ⓒ {year} Altversity</p>
        </footer>
    )
}

export default Footer;