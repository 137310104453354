// App.js

import React from 'react'; 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; 
// import Landing from './v1/Landing'; 
import Footer from './v1/Footer';
import Landing from './v1/Landing'; 
import './v1/css/App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
